<template>
  <div class="organism accordion-faq px-8 sm:my-20 my-8">
    <div class="accordion-header-container">
      <h2 class="accordion-header-title text-2xl leading-7 sm:leading-10 sm:text-[32px] font-bold font-figtree text-center text-gray-800">{{ headerTitle }}</h2>
      <div class="accordion-header-description text-gray-600" v-html="headerDescription"></div>
    </div>
    <div
      v-for="(item, index) in organism.accordion"
      :key="'item-' + index"
      :id="'accordion-panel-' + index"
      class="mx-auto max-w-screen-xl border-b last:border-b-0 border-gray-400 py-10 mx-8 accordion-panel"
    >
      <h3 class="accordion-header font-figtree text-xl md:text-[28px] font-semibold text-fr-secondary-dark">
        <button
          aria-expanded="false"
          :aria-controls="'accordion-content-' + index"
          class="grid grid-cols-[1fr_2rem] items-center w-full text-left cursor-pointer text-fr-secondary-dark hover:opacity-80"
          @click="toggleAccordion(index)"
        >
          <div class="flex flex-col">
            <span class="text-2xl md:text-[28px] title">{{ item.title1 }}</span>
            <span v-if="item.title2" class="text-base font-medium text-gray-400 md:text-lg subtitle font-figtree">{{ item.title2 }}</span>
          </div>
        </button>
      </h3>
      <div
        class="accordion-content overflow-hidden transition-[max-height] duration-300"
        :id="'accordion-content-' + index"
        :aria-hidden="!isExpanded(index)"
        :class="{ expanded: isExpanded(index) }"
      >
        <div v-html="item.description" class="text-gray-500 description mt-7"></div>
      </div>
    </div>
  </div>
</template>

<style>
.accordion-faq {
  .accordion-header-container {
    @apply text-center my-10;
  }

  .accordion-header {
    button {
      &::after {
        @apply w-7 h-7 bg-no-repeat block bg-contain;
        content: "";
        background-image: url("/img/heroicon-plus-circle--blue.png");
      }
      &[aria-expanded="true"]::after {
        background-image: url("/img/heroicon-minus-circle--blue.png");
      }
    }
  }

  .accordion-content {
    max-height: 0;
    transition: max-height 0.3s ease-out;
    overflow: hidden;

    &.expanded {
      max-height: 1000px; /* Adjust this value as needed */
    }

    .buttons {
      @apply mt-7 mb-1;
    }
  }
}
</style>

<script setup>
import { ref } from 'vue';

const props = defineProps({
  organism: {
    type: Object,
    required: true,
  },
  headerTitle: {
    type: String,
    required: false,
    default: '',
  },
  headerDescription: {
    type: String,
    required: false,
    default: '',
  },
});

const expandedIndex = ref(null);

const toggleAccordion = (index) => {
  expandedIndex.value = expandedIndex.value === index ? null : index;
};

const isExpanded = (index) => {
  return expandedIndex.value === index;
};
</script>