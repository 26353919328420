<template>
  <div v-if="organism.iframe" :class="[
      'container-full-width',
      'relative overflow-hidden organism simple-iframe px-0',
    ]">
    <div class="relative content flex items-center justify-center flex-col text-center mx-auto bg-fr-primary">
      <h2 v-if="organism.title1"
        class="title1 sm:mt-16 sm:mb-16 mt-8 mb-8 font-cg text-3xl sm:text-[3.5rem] sm:leading-[3.5rem] font-semibold text-white">
        {{ organism.title1 }}
      </h2>
      <p v-if="organism.title2" class="mb-9 max-w-4xl title2 font-figtree text-white">
        {{ organism.title2 }}
      </p>
    </div>
    <div class="iframe-content px-4" ref="iframeContainer" v-html="organism.iframe"></div>
  </div>
</template>

<script setup>
const props = defineProps({
  organism: Object,
});


const iframeContainer = ref(null);

onMounted(() => {
  if (props.organism.containerWidth === "full-width") {
    addFullWidthSupport();
  }
});
</script>
